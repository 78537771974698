import apiclient from "../networking/apiclient";
import cookies from "../misc/cookies";

const ACCESS_COOKIE_NAME = "AppAccessTokenJos";

const NULL_USER = {
    first_name: "",
    is_admin: false,
    groups: []
};

export default {

    isLoggedIn() {
        return new Promise(resolve => {

            apiclient.post("accounting/access/check", null).then(resp => {
                resolve(resp);
            });

        });
    },
    getAccessToken() {
        return cookies.getCookie(ACCESS_COOKIE_NAME);
    },
    login(email, password) {

        return new Promise(resolve => {

            let dto = {
                email: email,
                password: password
            };

            apiclient.post("accounting/access/login", dto).then(resp => {

                if (resp.success) {
                    cookies.setCookie(ACCESS_COOKIE_NAME, resp.token, 1);
                }

                resolve(resp.success);
            })
        })
    },
    logout() {
        return new Promise(resolve => {

            apiclient.post("accounting/access/logout", null).then(() => {
                cookies.eraseCookie(ACCESS_COOKIE_NAME);
                this.current = NULL_USER;
                resolve();
            })
        })
    },
    current: NULL_USER,
    loadInfo() {
        return new Promise(resolve => {

            if (this.current !== NULL_USER) {
                resolve(this.current);
                return;
            }

            this.isLoggedIn().then(resp => {

                if (!resp) {
                    this.current = NULL_USER;
                    resolve(this.current);
                    return;
                }

                apiclient.post("accounting/access/current", null).then((resp) => {
                    resp.is_admin = resp.is_admin === 1;
                    this.current = resp;
                    resolve(resp);
                })

            })
        })
    },
    async isInGroup(group) {
        await this.loadInfo();
        return this.current.groups.indexOf(group) > -1;
    }


}